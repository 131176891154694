.Nav nav {
    height: 92px;
    align-items: center;
}


.Nav .navbar-brand {
    width: 190px;
    height: 56px;
    display: block;

}

.Nav .navbar-dark {
    background-color: var(--Dark);
}


.Nav .navbar-brand img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Nav .navbar-dark .navbar-nav .nav-link,
.Nav .navbar-dark a {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    padding: 0;
}



.Nav .navbar-dark .navbar-nav .nav-item.withoutJoin {
    margin-right: 50px;
    margin-left: 30px;
}


.Nav .navbar-dark .navbar-nav .nav-item.withJoin,
.Nav .navbar-dark .navbar-nav .nav-item {
    margin: 0 20px;
}

.Nav .navbar-dark .navbar-nav .nav-item:nth-child(3) .nav-link {
    /* background-color: var(--Warn); */
    border-radius: 50px;
    padding: 10px 20px 10px 20px;
    position: relative;
}

.Nav  .cartNotification{
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #FD5558;
    color: white;
    font-size: 10px;
    pointer-events: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -6px;
    right: -4px;
    z-index: 11;
}

.Nav .navbar-dark .navbar-nav .nav-item.withoutJoin .nav-link:hover {
    border: 2px solid transparent;

}

.Nav .navbar-nav .nav-item .nav-link .iconify, .Nav .navbar-nav .nav-item.withJoin a svg   {
    font-size: 36px !important;
}


.Nav .navbar-dark .navbar-nav .nav-item {
    position: relative;
    color: inherit;
    text-decoration: none;
}

.Nav .navbar-dark .navbar-nav .nav-item:before,
.Nav .navbar-dark .navbar-nav .nav-item:after {
    content: '';
    position: absolute;
    transition: transform .5s ease;
}


.Nav .navbar-nav .borderHover:before {
    left: 0;
    bottom: -8px;
    width: 100%;
    height: 4px;
    border-radius: 10px;
    background: var(--Warn);
    transform: scaleX(0);
}

.Nav .navbar-nav .borderHover:hover:before {
    transform: scaleX(1);
}

.Nav .navbar-nav a.animated-button.victoria-two {
    padding: 6px 20px !important;
    border-radius: 30px;
}

.sideNav {
    max-width: 400px;
    width: 90%;
    box-shadow: rgb(0 0 0 / 52%) 10px 0px 50px 0.1px;
    background-color: var(--Dark);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    /* transition: .5s; */
    z-index: 9999;

}

.sideNav::-webkit-scrollbar {
    width: 0;
}

.sideNav .navContent {
    padding: 15px;
}

.sideNav .navContent .userName {
    font-size: 16px;
    font-weight: 600;
}

.sideNav .navContent .navCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #28293D;
    border-radius: 4px;
    height: 100px;
    position: relative;
}

.sideNav .navContent .navCard svg {
    font-size: 24px;
}

.sideNav .navContent .navCard .cardTitle {
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}

.sideNav .navContent .navCard .credits {
    position: absolute;
    font-size: 12px;
    color: var(--Warn);
    top: 15px;
    right: 10px;
}

.toggleSwitch .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 20px;
}

.toggleSwitch .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggleSwitch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    border: 2px solid #fff;

}

.toggleSwitch .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.toggleSwitch input:checked+.slider {
    border: 2px solid #fff;
}


.toggleSwitch input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.toggleSwitch .slider.round {
    border-radius: 34px;
}

.toggleSwitch .slider.round:before {
    border-radius: 50%;
}



.sideNav .social-icons ul {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.sideNav .social-icons ul li {
    list-style: none;
}

.sideNav .social-icons ul li a {
    width: 45px;
    height: 45px;
    background-color: #fff;
    margin: 0 10px;
    display: block;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.sideNav .social-icons ul li a .icon {
    position: relative;
    color: var(--Dark);
    transition: .5s;
    z-index: 3;
}

.sideNav .social-icons ul li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
}

.sideNav .social-icons ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: .5s;
    z-index: 2;
}

.sideNav .social-icons ul li a:hover:before {
    top: 0;
}

.sideNav .social-icons ul li:nth-child(1) a:before {
    background: #3b5999;
}

.sideNav .social-icons ul li:nth-child(2) a:before {
    background: #55acee;
}

.sideNav .social-icons ul li:nth-child(3) a:before {
    background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)
}

.sideNav .social-icons ul li:nth-child(4) a:before {
    background: #7289DA;

}



@media (max-width: 1399px) {
    .Nav .navbar-brand {
        width: 140px !important;
        height: auto;
    }

}

@media (max-width : 767px) {
    .navbar-brand {
        height: 45px !important;
    }

    .Nav .navbar-dark .navbar-nav .nav-item:nth-child(3) .nav-link{
        padding-left: 0;
    }

}