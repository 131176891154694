.Cart {
    padding-top: 100px;
    padding-bottom: 40px;
}

.Cart .emptyCart {
    background-color: #FFFFFF10;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Cart .emptyCart .title {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 25px;

}

.Cart .emptyCart .btnWarn {
    padding: 8px 38px;
}

.Cart .NotEmpty .cartSummary {
    background-color: #FFFFFF10;
    padding: 30px 0;
    text-align: center;
}

.Cart .NotEmpty .countDownTimer {
    background-color: var(--Warn);
}

.Cart .bgrow {
    background-color: #FFFFFF10;
    height: 50px;
    align-items: center;
}

.Cart .NotEmpty .cartTotal {
    padding: 16px;
    width: 100%;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 15%);
    height: max-content;
}

.Cart .NotEmpty .accordion-button:not(.collapsed) {
    background-color: transparent !important;
    color: #fff;
}



.Cart .NotEmpty .accordion-button,
.Cart .NotEmpty .accordion-item {
    background-color: transparent;
    color: #fff;
    border: 0;
}

.Cart .NotEmpty .accordion-button:focus {
    border-color: transparent;
    box-shadow: none;
}

.Cart .NotEmpty .accordion-button::after {
    filter: invert(99%) sepia(16%) saturate(2%) hue-rotate(264deg) brightness(119%) contrast(100%);
}

.Cart .NotEmpty .product-img {
    width: 120px;
    height: 150px;
    margin: 0 12px;
}

.Cart .NotEmpty .product-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Cart .NotEmpty .warnPill {
    background-color: var(--Warn);
    color: var(--Dark);
    height: 19.59px;
    max-width: 120px;
    min-width: 70px;
    border-radius: 12.8px;
    font-size: 12.8px;
    text-align: center;
    font-weight: 300;
    line-height: 18px;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
}

.Cart .NotEmpty .btnAdd{
    height: 19.59px;
    max-width: 120px;
    min-width: 70px;
    border-radius: 12.8px;
    font-size: 12.8px;
    text-align: center;
    font-weight: 300;
    line-height: 18px;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px dashed #fff;
}

.Cart .bgDef{
    background-color: #FFFFFF10;
    padding: 0 0 20px 0;
}

.Cart .h-80{
    height: 80px;
}

.Cart .borderTop{
    border-top: 0.25px solid #FFFFFF40
}

@media screen and (max-width : 768px) {

    .Cart .NotEmpty .product-img{
        min-width: 80px;
        width: 80px;
        min-height: 80px;
        height: 80px;
    }
}