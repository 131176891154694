.raffleDetail {
    padding-top: 140px;
}

.raffleDetail .bannerView {
    width: 100%;
    max-height: 450px;
    height: 100%;
}


.raffleDetail .bannerView img {
    width: 100%;
    height: 100%;
}


.raffleDetail .borderTop {
    border: 1px solid #FFFFFF10;
}


.raffleDetail .detailCard {
    background-color: rgb(0, 0, 0, 0.15);
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.raffleDetail .detailCard .title {
    font-size: 11px;
    text-align: center;
    font-weight: 800;
    color: #fff;
}

.raffleDetail .detailCard .price {
    color: var(--Warn);
    font-size: 10px;
}

.raffleDetail .detailProgress {
    margin-bottom: 30px;
    margin-top: 60px;
    position: relative;
}

.raffleDetail .detailProgress .progressTooltip {
    background-color: var(--Warn);
    color: var(--Dark);
    font-size: 12px;
    border-radius: 0.4em;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -35px;
    left: 42%;

}

.raffleDetail .detailProgress .progressTooltip .arrow {
    content: '';
    position: absolute;
    bottom: -9px;
    left: -10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: var(--Warn);
    border-bottom: 0;
    margin-left: -6px;
    margin-bottom: -6px;
}


.raffleDetail .detailProgress .progress {
    height: 0.5rem;
    max-height: 8px !important;
    border-radius: 8px;
    background-color: rgba(255, 189, 10, 0.2);
    width: 100%;

}

.raffleDetail .detailProgress .progress .progress-bar {
    width: 42%;
    background-color: #FFBD0A;
    max-height: 8px !important;
    height: 0.5rem;
    border-radius: 8px;

}


.raffleDetail .mobEntry{
    height: max-content;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    background-color: #1C1C27;
    color: #FFFFFF !important;
    position: fixed;
    bottom: 0;
    z-index: 1009;
    width: 100%;
    left: 0;
    padding: 15px 15px 0 15px;
    box-shadow: 0 6px 30px 0 #ffbd0a;
    border-top-left-radius: 50% 20px;
    border-top-right-radius: 50% 20px;
    min-height: 170px;
}

.raffleDetail .mobEntry .counter{
    font-size: 48px;
}

.raffleDetail .mobEntry .ticketContain{
    background-color: #28293D;
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-weight: 800;

}

.raffleDetail .mobEntry .btnWarn{
    color: var(--Dark);
    font-weight: 800;
}

@media screen and (max-width : 767px) {

    .raffleDetail {
        padding-top: 100px;
    }


    .raffleDetail .bannerView {
        width: 100%;
        height: auto;
        position: relative;
        width: 100vw;
        max-width: 100vw;
        border-radius: 0;
        left: -24px;
    }

    .raffleDetail .fs-50 {
        font-size: 36px;
        text-align: center;
    }

    .raffleDetail .detailCard{
        padding: 0;
    }


}