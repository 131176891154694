:root {
  --Dark: #1C1C27;
  --Warn: #FFBD0A;
  --White: #fff;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  background-color: var(--Dark);
  color: var(--White);
}

body::-webkit-scrollbar {
  width: 0;
}

a:link,
a:visited {
  color: var(--Warn);
  text-decoration: none;
}




.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-39 {
  font-size: 39px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-42 {
  font-size: 42px !important;
}

.fs-44 {
  font-size: 44px;
}

.fs-46 {
  font-size: 46px !important;
}

.fs-50 {
  font-size: 50px;
}


.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}



/* text color */

.textWarn {
  color: var(--Warn);
}

.textDark{
  color: var(--Dark);
}

.btnWarn {
  border-radius: 6px !important; 
  font-size: 16px !important;
  font-weight: 500 !important;
  text-decoration: none !important;
  background-color: var(--Warn);
}

.pointer{
  cursor: pointer;
}


 .timer{
  background-color: #FD5558;
  border-radius: 8.2px;
  font-size: 19.12px;
  padding: 4.1px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

 .timer .timeHead{

}

 .timer .timeBody{
  background-color: #fff;
  color: #FD5558;
  border-radius: 0 0 4.1px 4.1px;
}




.flex-1{
  flex: 1;
}















.pulse {
  animation: pulse 2s infinite;
}

.pulse:hover {
  animation: none;
  transition: all  .5s ease-in-out;

}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}




/* Global Button Styles */
a.animated-button:link, a.animated-button:visited {
	position: relative;
	display: block;
	font-size:14px;
	text-align: center;
	text-decoration: none;
	overflow: hidden;
	letter-spacing: .08em;
	border-radius: 0;
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}
a.animated-button:link:after, a.animated-button:visited:after {
	content: "";
	position: absolute;
	height: 0%;
	left: 50%;
	top: 50%;
	width: 150%;
	z-index: -1;
	-webkit-transition: all 0.75s ease 0s;
	-moz-transition: all 0.75s ease 0s;
	-o-transition: all 0.75s ease 0s;
	transition: all 0.75s ease 0s;
}
a.animated-button:link:hover, a.animated-button:visited:hover {
	color: #FFF;
	text-shadow: none;
}
a.animated-button:link:hover:after, a.animated-button:visited:hover:after {
	height: 450%;
}
a.animated-button:link, a.animated-button:visited {
	position: relative;
	display: block;
	color: #fff;
	font-size:14px;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	overflow: hidden;
	letter-spacing: .08em;
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}

a.animated-button.victoria-two {
	border: 2px solid var(--Warn);
}
a.animated-button.victoria-two:after {
	background: var(--Warn) !important;
	-moz-transform: translateX(-50%) translateY(-50%) rotate(25deg);
	-ms-transform: translateX(-50%) translateY(-50%) rotate(25deg);
	-webkit-transform: translateX(-50%) translateY(-50%) rotate(25deg);
	transform: translateX(-50%) translateY(-50%) rotate(25deg);
}
