.winnersGallery{
    padding-top: 130px;
}

.winnersGallery .galleryCard{
    width: 100%;
    height: auto;
    position: relative;

}

.winnersGallery .galleryCard .gallery-img{
    width: 100%;
    height: 300px;
}


.winnersGallery .galleryCard .gallery-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.winnersGallery .galleryCard .overLay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: .5s ease;
    background-color: #2A3138;
    opacity: 0;
}

.winnersGallery  .galleryCard .overlay-withtext{
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.winnersGallery .galleryCard .overLay:hover{
    opacity: 0.85; 

}


.winnersGallery .galleryCard .ticket-with-text{
    background-image: url('../../assets/white-ticket-shape-outline.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}