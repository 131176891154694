.Faq{
    padding: 140px 0;
}

.Faq .accordion, .Faq .accordion .accordion-button, .Faq .accordion-item, .Faq .accordion-button:not(.collapsed){
    color: #fff;
    background-color: transparent !important;
    box-shadow: none;
}

.Faq .accordion-item{
    border: 0;
    border-bottom: 1px solid #fff !important;
}

.Faq .accordion-button{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}


.Faq .accordion-button:focus{
    box-shadow: none;
    border-color: transparent;
}

.Faq .accordion-button::after{
    filter: invert(99%) sepia(16%) saturate(2%) hue-rotate(264deg) brightness(119%) contrast(100%);

}