.Signup{
    padding-top: 200px;
}


.Signup .signUp-form{
    padding: 40px 0;
}

.Signup .signUp-form input, .Signup .signUp-form .btnWarn{
    padding:  0.75rem;
    font-size: 1rem;
}

.Signup .signUp-form .title{
    font-size: 62px;
    font-weight: 800;
    margin-bottom: 0.5rem;
}

.Signup .signUp-form input, .Signup .signUp-form .btnWarn{
    border-radius: 4px !important;
}

.Signup .social-icons{
    width: 50%;
    margin: 0 auto;
}

.Signup .social-icons ul {
    display: flex;
    margin: 0;
    padding: 0;
    margin-top: 24px;
    flex-wrap: wrap;
    justify-content: center;
}

.Signup .social-icons ul li {
    list-style: none;
}

.Signup .social-icons ul li a {
    width: 45px;
    height: 45px;
    background-color: #fff;
    margin: 0 10px;
    display: block;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.Signup .social-icons ul li a .icon {
    position: relative;
    color: var(--Dark);
    transition: .5s;
    z-index: 3;
}

.Signup .social-icons ul li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
}

.Signup .social-icons ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: .5s;
    z-index: 2;
}

.Signup .social-icons ul li a:hover:before {
    top: 0;
}

.Signup .social-icons ul li:nth-child(1) a:before {
    background: #3b5999;
}

.Signup .social-icons ul li:nth-child(2) a:before {
    background: #55acee;
}

.Signup .social-icons ul li:nth-child(3) a:before {
    /* background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf) */
    background:linear-gradient(-120deg, #4285f4, #34a853, #fbbc05, #ea4335);
}

.Signup .social-icons ul li:nth-child(4) a:before {
    background: #0e76a8;
}


@media screen and (max-width : 767px) {
    .Signup {
        padding-top: 100px;
    }

    .Signup .signUp-form .title {
        font-size: 50px;
    }

    .Signup .social-icons{
        width: 100%;
    }
    
}