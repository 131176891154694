.raffleCard {
    background-color: #28293D;
    border-radius: 8px;
    transition: all .5s ease-in-out;
    cursor: pointer;
    position: relative;


}

.raffleCard .raffle-img{
    width: 100%;
    height: 370px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;

}

.raffleCard .raffle-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.raffle-img .timer{
    position: absolute;
    top: 16px;
    right: 16px;
    width: 50%;
    transition: all .5s ease-in-out;

}


.raffleCard .raffleBody{
    padding: 1.25rem;
}


.raffleCard .progress  {
    height: 0.5rem;
    max-height: 8px !important;
    border-radius: 8px;
    background-color: rgba(255, 189, 10, 0.2);
    width: 80%;

}

.raffleCard .progress .progress-bar  {
    width: 71.09036345448483%;
    background-color: #FFBD0A;
    max-height: 8px !important;
    height: 0.5rem;
    border-radius: 8px;

}

.raffleCard .raffleBody .cardPannel{
    opacity: 0.8 !important;
    font-size: 10px;
    align-items: baseline;
    color: #fff;
}

.raffleCard:hover{
    transform: scale(1.1);
    transition: all .4s ease-in-out;
    z-index: 11;
}




@media screen and (max-width : 767px) {
    .raffleCard .raffle-img{
        /* background: url('../../assets/taxFree.webp'), linear-gradient(0deg, rgba(40,41,61,1) 25%, rgba(40,41,61,0) 50%); */
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 300px;
    }
    .raffleCard .btnWarn{
        border-radius: 0 0 6px 6px !important;
        position: absolute !important;
        left: 0;
        bottom: 0;
        width: 100%;
    }
}