.Footer footer {
    border-top: 8px solid var(--Warn);
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}

.Footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}


.Footer .subFooter .footerLogo {
    max-width: 200px;
    width: 200px;
    height: 100px;
}

.Footer .subFooter .footerLogo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.Footer .social-icons ul {
    display: flex;
    margin-top: 50px;
}

.Footer .social-icons ul li {
    list-style: none;
}

.Footer .social-icons ul li a {
    width: 45px;
    height: 45px;
    background-color: #fff;
    margin: 0 10px;
    display: block;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.Footer .social-icons ul li a .icon {
    position: relative;
    color: var(--Dark);
    transition: .5s;
    z-index: 3;
}

.Footer .social-icons ul li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
}

.Footer .social-icons ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: .5s;
    z-index: 2;
}

.Footer .social-icons ul li a:hover:before {
    top: 0;
}

.Footer .social-icons ul li:nth-child(1) a:before {
    background: #3b5999;
}

.Footer .social-icons ul li:nth-child(2) a:before {
    background: #55acee;
}

.Footer .social-icons ul li:nth-child(3) a:before {
    background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)
}

.Footer .social-icons ul li:nth-child(4) a:before {
    background: #7289DA;
}

.Footer .footerLinks li {
    margin: 10px 0;
}

.Footer .footerLinks a {
    color: #fff;
}


.borderWarn{
    border-top: solid 2px #FFBD0A;
    padding-top: 76px;
}

.Footer .footerLinks a,
.Footer .footerLinks li>div {
    position: relative;
    color: inherit;
    text-decoration: none;
    line-height: 24px;
}

.Footer .footerLinks a:before,
.Footer .footerLinks a:after,
.Footer .footerLinks li>div:before,
.Footer .footerLinks li>div:after {
    content: '';
    position: absolute;
    transition: transform 1s ease;
}


.Footer .footerLinks .hoverBottom {
    display: inline-flex;
    padding-top: 4px;
    padding-bottom: 5px;
    overflow: hidden;
}

.Footer .footerLinks .hoverBottom:before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background: var(--Warn);
    transform: translateX(-101%);
    border-radius: 10px;
}

.Footer .footerLinks .hoverBottom:hover:before {
    transform: translateX(0);
}


@media screen and (max-width : 767px) {
    .Footer .subFooter .footerLogo {
        margin: 0 auto;
    }

    .Footer .social-icons ul {
        justify-content: center;
        margin-top: 24px;
    }
    
}