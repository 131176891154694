.raffleList{
    padding-top: 100px;
}

.raffleList .topBanner{
    background-color: #28293D;
    padding-top: 15px;
    padding-bottom: 15px;
}

.raffleList .topBanner .applePay{
    filter: invert(99%) sepia(16%) saturate(2%) hue-rotate(264deg) brightness(119%) contrast(100%);
}


.raffleList .bannerImg{
    margin-bottom: 100px;
}



.raffleList .statCard{
    background: var(--Warn);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.raffleList .statCard h2{
    font-weight: 800;
    font-size: 80px;
    margin-bottom: 0;
    color: var(--Dark);
}

.raffleList .statCard h6{
    color: var(--Dark);
    font-size: 1.5rem;

}
