.Charity {
    padding-top: 120px;
}

.Charity .raisedHeading {
    font-size: 27px;
    font-weight: 400;
    text-align: center;
}

.Charity .raisedAmmount {
    text-align: center;
    color: var(--Warn);
    font-size: 5rem;
    font-weight: 800;
}

.Charity .cardSection {
    padding: 100px 0;
}


.Charity .cardSection .charityPartner {
    padding: 20px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 15%);
    box-shadow: 0px 0px 6px 2px rgb(0 0 0 / 15%);
    min-height: 307px;
}

.textGray {
    color: #cacccd;
}

.charityPartner .logo-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.charityPartner .logo-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}

.Charity .charityPartner:hover {
    transform: scale(1.06);
    transition: transform 0.3s;
    -webkit-box-shadow: 0px 0px 20px 0px rgb(38 202 211 / 40%);
    box-shadow: 0px 0px 20px 0px rgb(38 202 211 / 40%);
    cursor: pointer;

}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.modal-content {
    background-color: transparent !important;
}

.innerModal{
    background-color: var(--Dark);
    border-top: 8px solid #FFBD03;
    padding: 45px 25px 25px;
    text-align: center;
    border-radius: 0.3rem 0.3rem 0 0;
}

.innerModal .logo{
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
    margin-bottom: 40px;
}

.innerModal .logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.innerModal .title{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

.innerModal .desc{
    font-size: 15px;
    font-weight: 400;
}
