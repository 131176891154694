.Home {
    padding-top: 116px;
}

.Home .topBanner {
    background-color: #28293D;
    padding-top: 15px;
    padding-bottom: 15px;
}

.Home .topBanner .applePay {
    filter: invert(99%) sepia(16%) saturate(2%) hue-rotate(264deg) brightness(119%) contrast(100%);
}


.Home .bannerImg {
    margin-bottom: 55px;
}


.Home .cardWithText {
    width: 100%;
    background: url('../../assets/8000-X1.png'), linear-gradient(transparent, #302c3c);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 50%);
    margin-bottom: 3%;
    margin-top: 1%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 30px;

}


.Home .cardWithText h3 {
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 52px;
    color: #fff;
    font-weight: 800;
    letter-spacing: 0;
    text-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
}


.Home .cardWithText .price {
    margin-right: 10px;
    font-weight: 800;
    font-size: 20px;
    color: var(--Warn);
    margin-bottom: 1rem;

}

.Home .cardWithText .progress {
    height: 0.5rem;
    max-height: 8px !important;
    border-radius: 8px;
    background-color: rgba(255, 189, 10, 0.2);
    width: 80%;

}

.Home .cardWithText .progress .progress-bar {
    width: 71.09036345448483%;
    background-color: #FFBD0A;
    max-height: 8px !important;
    height: 0.5rem;
    border-radius: 8px;

}

.Home .cardWithText .ticketSold {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    text-shadow: black 0 1px 10px;
    color: #fff;
}

.Home .cardWithText .btnWarn {
    color: #0C1835;
    padding: 15px 42px;
    word-break: break-word;
    display: inline-flex;
    text-transform: uppercase;
    white-space: nowrap;
    border-radius: 6px;
    background-color: var(--Warn);
}

.Home .cardWithText.bannerchange {
    background-image: url('../../assets/Travel-1.png');
}

.Home .cardWithText .timeBadge {
    background-color: rgba(12, 24, 53, 0.8);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 5.54px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    text-transform: uppercase;
    line-height: 25px;
    white-space: nowrap;
}

.Home .statCard {
    background: #00000015;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.Home .statCard h2 {
    font-weight: 800;
    font-size: 80px;
    margin-bottom: 0;
    color: var(--Warn);
}

.Home .statCard h6 {
    color: var(--Warn);
    font-size: 1.5rem;

}


.Home .how-works {
    margin: 70px 0;
}

.Home .how-works .worksCard {
    background-color: #28293D;
    border-radius: 16px;
    color: #FFFFFF;
    padding: 20px 14px;
    text-align: center;
}

.Home .how-works .worksCard .digitCircle {
    background: linear-gradient(180deg, #FFD70D 0%, #FFAE05 100%);
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 30px;
    margin: 20px auto;
}

.Home .all-raffle {
    margin-bottom: 20px;
}

.Home .all-raffle .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.4;

}

.Home .all-raffle .filter {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.Home .all-raffle .filter li:not(:last-child) {
    margin-right: 48px;
}


.Home .all-raffle .filter li a {
    color: #FFFFFF60;
    font-size: 20px;
    opacity: 0.8;
}

.Home .all-raffle .filter li a.filterActive {
    color: #FFBD0A;
    background: #FFBD0A15;
    border-radius: 16px;
    padding: 0 1.5rem;
    padding-bottom: 2px;
}


.Home .rating {
    margin: 20px 0;
}

.Home .rating .ratingCard {
    background-color: #28293D;
    border-radius: 10px;
    padding: 1.5rem 0;
}


.Home .marqui-img {
    width: 120px;
    height: 120px;
    display: inline-block;
    margin-right: 20px;
}

.Home .marqui-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}




@media screen and (min-width: 1200px) {
    .Home .cardWithText h3 {
        font-size: 3.5em !important;
    }

}

@media screen and (min-width: 768px) {
    .Home .cardWithText h3 {
        font-size: 3em !important;
    }

}

@media screen and (min-width: 600px) {
    .Home .cardWithText h3 {
        font-size: 2em !important;
    }

}


@media screen and (max-width : 768px) {

    .Home {
        padding-top: 94px;
    }

    .Home .bannerImg {
        margin-bottom: 35px;
    }

    .Home .cardWithText {
        background-position: center center;
        background-repeat: no-repeat;
        min-height: 420px;
        border-radius: 8px;
        padding: 10px;
        position: relative;
    }

    .Home .cardWithText .btnWarn {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        border-radius: 0 0 6px 6px !important;
        text-align: center;
        justify-content: center;
    }

    .Home .cardWithText .timeBadge {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .Home .cardWithText h3 {
        text-transform: uppercase;
        font-size: 2rem;
        line-height: 40px;
    }

    .Home .statCard h2 {
        font-size: 50px;
    }


    .Home .all-raffle .filter {
        justify-content: center;
    }

    .Home .all-raffle .filter li:not(:last-child) {
        margin-right: 16px;
    }

    .Home .all-raffle .filter li a {
        font-size: 16px;
    }


}