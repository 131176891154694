.Raffles{
    padding: 140px 0;
}
.Raffles .raffleTab{
    text-align: center;
    font-size: 18px;
    border-bottom: 1px solid #d1d1d1;
    padding-bottom: 15px;
    margin-bottom: 30px;
    margin-top: 50px;
}