.Winners {
    padding-top: 100px;
}

.Winners .bgWinner{
    background-color: #FFFFFF10;
}

.Winners .searchContainer{
    padding-top: 70px;
    padding-bottom: 70px;

}

.Winners .searchContainer input{
    width: 100%;
    height: 56px;
    background-color: #FFFFFF10;
    padding-left: 50px;
    color: rgb(117, 117, 117);
    font-size: 16px;
    font-weight: 500;
}

.Winners .searchContainer input, .Winners .searchContainer input:focus{
    outline: 0;
    border: 0;
}

.Winners .searchContainer svg{
    position: absolute;
    top: 22px;
    left: 16px;
    color: rgb(117, 117, 117);

}

.WinnersThisMonth{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 100px;
    border-top: 2px solid #97979750;
    margin-top: 20px;
    row-gap: 20px;
}

.Winners .winnerCard{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.Winners .winnerCard .winner-img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 16px;
}

.Winners .winnerCard .winner-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;

}


@media screen and (max-width: 750px){
    .WinnersThisMonth {
        grid-template-columns: repeat(1, 1fr) !important;
    }

}