.Login {
    padding: 200px 0;
}

.Login .title{
    font-size: 62px;
    font-weight: 800;
    margin-bottom: 0.5rem;
}

.Login [type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.Login [type="radio"]:checked + label,
.Login [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #fff;
}
.Login [type="radio"]:checked + label:before,
.Login [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.Login [type="radio"]:checked + label:after,
.Login [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: var(--Warn);
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.Login [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.Login [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.Login .social-icons ul {
    display: flex;
    margin: 0;
    padding: 0;
    margin-top: 24px;
    flex-wrap: wrap;
    justify-content: center;
}

.Login .social-icons ul li {
    list-style: none;
}

.Login .social-icons ul li a {
    width: 45px;
    height: 45px;
    background-color: #fff;
    margin: 0 10px;
    display: block;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.Login .social-icons ul li a .icon {
    position: relative;
    color: var(--Dark);
    transition: .5s;
    z-index: 3;
}

.Login .social-icons ul li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
}

.Login .social-icons ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: .5s;
    z-index: 2;
}

.Login .social-icons ul li a:hover:before {
    top: 0;
}

.Login .social-icons ul li:nth-child(1) a:before {
    background: #3b5999;
}

/* .Login .social-icons ul li:nth-child(2) a:before {
    background: #55acee;
} */

.Login .social-icons ul li:nth-child(2) a:before {
    /* background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf) */
    background:linear-gradient(-120deg, #4285f4, #34a853, #fbbc05, #ea4335);
}

/* .Login .social-icons ul li:nth-child(4) a:before {
    background: #0e76a8;
} */


@media screen and (max-width : 767px) {
    
    .Login {
        padding: 100px 0;
    }

    .Login .title {
        font-size: 50px;
    }

    
}

